import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { authenticatedApi } from '../api';
import { Settings } from '../entities/Settings/SettingsTypes';
import { useAuthenticationService } from './AuthenticationService';
import { ApiSuccessCallback, ApiErrorCallback } from './callback';

function useSettings() {
  const [settings, setSettings] = useState<Settings>({});
  const [loading, setLoading] = useState(false);

  const { authenticationState } = useAuthenticationService();
  const writeToStorage = (settings: Settings) => {};

  const getSettingsFromServer = async (
    onSuccess?: ApiSuccessCallback<Settings>,
    onError?: ApiErrorCallback
  ) => {
    const { data, error } = await authenticatedApi(
      authenticationState!.token
    ).get<Settings>('/settings/');
    if (error) return onError ? onError(error) : undefined;
    if (data) {
      writeToStorage(data);
      setSettings(data);
    }
    if (data && onSuccess) {
      onSuccess(data);
    }
    setLoading(false);
    return undefined;
  };

  const loadSettingsFromStorage = () => {};

  const changeSettings = async (
    settings: Settings,
    onSuccess?: ApiSuccessCallback<Settings>,
    onError?: ApiErrorCallback
  ) => {
    const { data, error } = await authenticatedApi(
      authenticationState!.token
    ).put<Settings, Settings>('/settings/', settings);

    if (error && onError) onError(error);
    if (data) {
      writeToStorage(data);
      setSettings(data);
    }
    if (data && onSuccess) {
      onSuccess(data);
    }
  };

  return {
    language: settings.language,
    loadSettingsFromStorage,
    getSettingsFromServer,
    loading,
    changeSettings,
  };
}

export const { Provider: SettingsProvider, useContainer: useSettingsService } =
  createContainer(useSettings);
