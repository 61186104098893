import axios, { AxiosError, AxiosResponse } from 'axios';
import settings from '../constants/settings.json';

interface DataOrError<Data> {
  data?: Data;
  error?: AxiosError;
  res?: AxiosResponse<Data>;
}

export class Api {
  static api = axios.create({
    baseURL: (() => {
      if (
        process.env.NODE_ENV === 'production' &&
        process.env.REACT_APP_PROD_URL
      )
        return process.env.REACT_APP_PROD_URL;
      else if (process.env.NODE_ENV === 'production' && settings.prodUrl)
        return settings.prodUrl;
      else return settings.devUrl;
    })(),
  });

  private static createAPIEndpoint() {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_PROD_URL)
      return process.env.REACT_APP_PROD_URL;
    else if (process.env.NODE_ENV === 'production' && settings.prodUrl)
      return settings.prodUrl;
    else return settings.devUrl;
  }

  static logout() {
    this.api = axios.create({
      baseURL: this.createAPIEndpoint(),
    });
    axios.defaults.baseURL = this.createAPIEndpoint();
    axios.defaults.headers = undefined;
    return this;
  }

  static authenticate(token: string) {
    if (!token) {
      return this.logout();
    }
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    this.api = axios.create({
      baseURL: this.createAPIEndpoint(),
      headers,
    });
    axios.defaults.baseURL = this.createAPIEndpoint();
    axios.defaults.headers = headers;

    return this;
  }

  static async get<Data>(url: string): Promise<DataOrError<Data>> {
    try {
      const res = await this.api.get<Data>(url);
      return { data: res.data, res };
    } catch (error) {
      return { error: error as AxiosError };
    }
  }

  static async post<Data, Parameters>(
    url: string,
    params: Parameters
  ): Promise<DataOrError<Data>> {
    try {
      const res = await this.api.post<Data>(url, params);
      return { data: res.data, res };
    } catch (error: any) {
      return { error };
    }
  }

  static async patch<Data, Parameters>(
    url: string,
    params: Parameters
  ): Promise<DataOrError<Data>> {
    try {
      const res = await this.api.patch(url, params);
      return { data: res.data, res };
    } catch (error: any) {
      return { error };
    }
  }

  static async delete<Data>(url: string): Promise<DataOrError<Data>> {
    try {
      const res = await this.api.delete(url);
      return { data: res.data, res };
    } catch (error: any) {
      return { error };
    }
  }

  static async put<Data, Parameters>(
    url: string,
    params: Parameters
  ): Promise<DataOrError<Data>> {
    try {
      const res = await this.api.put(url, params);
      return { data: res.data, res };
    } catch (error: any) {
      return { error };
    }
  }
}
