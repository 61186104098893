import React from "react";
import { Redirect } from "react-router";
import { Route, Switch, useHistory } from "react-router-dom";
import routes from "./constants/routes.json";
import Authenticate from "./screens/Authenticate";
import { useAuthenticationService } from "./services/AuthenticationService";
import ShowQRCode from "./screens/ShowQRCode";

import { useEffect, useState } from "react";

const Routes: React.FC = () => {
  const [initialized, setInitialized] = useState(false);
  const {
    authenticationState,
    authCookieWasSet,
    login,
    getUsernameAndPassword,
  } = useAuthenticationService();
  const authenticated = !!authenticationState?.token;
  const history = useHistory();

  useEffect(() => {
    console.log(!authenticated);
    console.log(authCookieWasSet());
    const setInitializedOnSuccess = () => {
      setInitialized(true);
      console.log("history push");
      history.push(routes.QR_CODE);
    };
    if (!authenticated && authCookieWasSet()) {
      login(getUsernameAndPassword(), setInitializedOnSuccess);
    } else {
      setInitialized(true);
    }
  }, []);

  if (!initialized) return null;

  console.log(authCookieWasSet());

  return (
    <>
      {authCookieWasSet() ? (
        <Switch>
          <Route exact path={routes.QR_CODE} component={ShowQRCode} />
          <Route exact component={ShowQRCode} />
        </Switch>
      ) : (
        <Switch>
          <Route exact path={routes.ROOT} component={Authenticate} />
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      )}
    </>
  );
};

export default Routes;
