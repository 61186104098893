import React from 'react';
import EmployeeDetails from './EmployeeDetails';
import EmployeeApi from '../../api/EmployeeApi';

interface EmployeeDetailsPopUpProps {
  emp_detail: {
    active: boolean;
    is_in_pause: boolean;
    employee: number;
  };
  isOpen: boolean;
  close: () => void;
  selectedEmp: number;
}

export const EmployeeDetailsPopUp: React.FC<EmployeeDetailsPopUpProps> = ({
  emp_detail,
  isOpen,
  close,
  selectedEmp,
}) => {
  const employeesInfo = EmployeeApi.hooks.useEmployeeQuery();
  let res = employeesInfo[0]?.data?.find(
    (i) => parseInt(i.id) === emp_detail.employee
  );
  return (
    <>
      {parseInt(res?.id || '') === selectedEmp && (
        <EmployeeDetails
          emp_detail={emp_detail}
          isOpen={isOpen}
          close={close}
        />
      )}
    </>
  );
};
