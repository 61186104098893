import {
  BoxProps,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useField } from "formik";
import React, { InputHTMLAttributes } from "react";
import useTranslate from "../../utils/translate";

type InputFieldProps = BoxProps &
  InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> & {
    name: string;
    label: string;
    textarea?: boolean;
    row?: boolean;
    labelParams?: string[];
    errorParams?: string[];
    placeholderParams?: string[];
    format?: string;
  };

export const InputField: React.FC<InputFieldProps> = ({
  label,
  size: _,
  textarea = false,
  row = false,
  labelParams,
  errorParams,
  placeholderParams,
  format,
  ...props
}) => {
  const InputOrTextArea = textarea ? Textarea : Input;
  const [field, { error }] = useField(props);
  const translate = useTranslate();
  return (
    <FormControl isInvalid={!!error}>
      <Flex direction={row ? "row" : "column"} align="start">
        <FormLabel htmlFor={field.name}>
          {translate(label, labelParams)}
        </FormLabel>
        <Flex w="full" direction="row" align="start">
          <InputOrTextArea
            {...field}
            {...props}
            id={field.name}
            placeholder={translate(props.placeholder, placeholderParams)}
          />
          {format ? (
            <Text>{` ${translate("input-field.format")}: ${format}`}</Text>
          ) : null}
        </Flex>

        {error ? (
          <FormErrorMessage>{translate(error, errorParams)}</FormErrorMessage>
        ) : null}
      </Flex>
    </FormControl>
  );
};
