import React from 'react';
import { useAuthenticationService } from './services/AuthenticationService';
import { Flex, Spinner } from '@chakra-ui/react';

// TODO: Login mit unserem server, ReactRouter, Chakra ist installiert, UnstatedNext

const App: React.FC = (props) => {
  const { children } = props;
  const { loading } = useAuthenticationService();
  if (loading) {
    return (
      <Flex mt="15vh">
        <Spinner />
      </Flex>
    );
  }

  return <div>{children}</div>;
};

export default App;
