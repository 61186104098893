import { theme as chakraTheme, extendTheme } from '@chakra-ui/react';


const fonts = { ...chakraTheme.fonts, mono: `'Menlo', monospace` };

const fontSizes = {
  ...chakraTheme.fontSizes,
  md: 20,
  lg: 25,
  xl: 35
};

const primaryColor = '#415c24';
const primaryHoverColor = '#419024';
const dangerColor = 'red.500';
const overflowColor = 'red.500';
const modalCancelButtonColor = 'blue.500';
const modalSubmitButtonColor = 'green.500';
const tabColor = 'green';
const linkColor = '#2f855a';
const componentFontColor = 'white';
const dividerBorderColor = 'black';
const draggableDefaultColor = '#EFEFEF';
const draggableDragColor = '#D8D8D8';

const breakpoints = ['40em', '52em', '64em'];
const theme = extendTheme({
  colors: {
    primary: primaryColor,
    danger: dangerColor,
    draggableDefault: draggableDefaultColor,
    draggableDrag: draggableDragColor,
    link: linkColor,
    tab: tabColor
  },
  fonts,
  fontSizes,
  breakpoints,
  components: {
    Button: {
      variants: {
        solid: {
          bg: primaryColor,
          color: componentFontColor,
          _hover: {
            bg: primaryHoverColor,
            color: componentFontColor
          }
        },
        danger: {
          ...chakraTheme.components.Button.variants.solid,
          bg: dangerColor,
          color: componentFontColor
        },
        submit: {
          ...chakraTheme.components.Button.variants.solid,
          bg: modalSubmitButtonColor,
          color: componentFontColor
        },
        cancel: {
          ...chakraTheme.components.Button.variants.solid,
          bg: modalCancelButtonColor,
          color: componentFontColor
        }
      }
    },
    Link: {
      baseStyle: () => ({
        color: linkColor
      })
    },
    Divider: {
      baseStyle: () => ({
        color: dividerBorderColor
      })
    },
    Text: {
      variants: {
        danger: {
          color: dangerColor,
          fontSize: 20
        }
      }
    },
    CustomProgress: {
      baseStyle: () => ({
        height: 15,
        width: '100%',
        border: '2px',
        backgroundColor: primaryColor,
        overflowColor
      })
    }
  },
  icons: {
    logo: {
      path: (
        <svg
          width="3000"
          height="3163"
          viewBox="0 0 3000 3163"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="3000" height="3162.95" fill="none" />
          <path
            d="M1470.89 1448.81L2170 2488.19H820V706.392H2170L1470.89 1448.81ZM1408.21 1515.37L909.196 2045.3V2393.46H1998.84L1408.21 1515.37Z"
            fill="currentColor"
          />
        </svg>
      ),
      viewBox: '0 0 3000 3163'
    },
    listAlt: {
      path: (
        <svg
          width="3000"
          height="3163"
          viewBox="0 0 3000 3163"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19 5v14H5V5h14m1.1-2H3.9c-.5 0-.9.4-.9.9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V3.9c0-.5-.5-.9-.9-.9zM11 7h6v2h-6V7zm0 4h6v2h-6v-2zm0 4h6v2h-6zM7 7h2v2H7zm0 4h2v2H7zm0 4h2v2H7z" />
        </svg>
      )
    }
  }
});

export default theme;
