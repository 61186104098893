import { useQuery } from 'react-query';
import { Api } from './api';
interface CompanyLogo {
  picture: string;
  rotation: number;
}

const queries = {
  getCompanyLogo(token: string) {
    return Api.authenticate(token)
      .get<CompanyLogo>('/logo/company/')
      .then((res) => res.data);
  },
};

const hooks = {
  useCompanyLogo(token: string) {
    const query = useQuery(
      'COMPANY_LOGO',
      () => queries.getCompanyLogo(token),
      {
        cacheTime: 1000 * 60 * 60,
        staleTime: 1000 * 60 * 60,
      }
    );
    return query;
  },
};

const CompanyLogoApi = {
  queries,
  hooks,
};

export default CompanyLogoApi;
