import React from 'react';
import {
  HeadingProps as CHeadingProps,
  Heading as ChakraHeading
} from '@chakra-ui/react';
import useTranslate from '../../utils/translate';

type HeadingProps = CHeadingProps & { params?: string[] };

export const Heading: React.FC<HeadingProps> = ({
  params,
  children,
  ...props
}) => {
  const translate = useTranslate();
  return (
    <ChakraHeading {...props}>{translate(children, params)}</ChakraHeading>
  );
};
