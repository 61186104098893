import {
  Popover,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import useTranslate from "../../utils/translate";
import React from "react";

interface EmployeeDetailsProps {
  emp_detail: any
  isOpen: boolean
  close: ()=>void
}

const EmployeeDetails: React.FC<EmployeeDetailsProps> = ({ emp_detail, isOpen, close }) => {
  const translate = useTranslate();
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={close}
      placement="bottom"
      closeOnBlur={false}
      arrowPadding={20}
    >
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">
          {translate(emp_detail.current_job_type)} {translate("Job")}
        </PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          {translate("Job")} {translate(emp_detail.current_job)}
          {translate(emp_detail.current_work_time)}
        </PopoverBody>
        <PopoverFooter d="flex" justifyContent="flex-end"></PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default EmployeeDetails;
