import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { createContainer } from 'unstated-next';
import { Api } from '../api/api';
import { AuthenticationRequest } from '../entities/Authentication/AuthenticationRequest';
import { AuthenticationResponse } from '../entities/Authentication/AuthenticationRespsone';
import { ApiSuccessCallback, ApiErrorCallback } from './callback';

function useAuthentication() {
  const [authenticationState, setAuthenticationState] =
    useState<AuthenticationResponse>();
  const [error, setError] = useState<AxiosError<any> | undefined>();
  const [loading, setLoading] = useState(false);
  const authUsernameCookieName = 'authUsernameCookie';
  const authPasswordCookieName = 'authPasswordCookie';
  const [cookies, setCookie, removeCookie] = useCookies([
    authUsernameCookieName,
    authPasswordCookieName,
  ]);

  interface CookieSetOptions {
    path?: string;
    expires?: Date;
    maxAge?: number;
    domain?: string;
    secure?: boolean;
    httpOnly?: boolean;
    sameSite?: boolean | 'none' | 'lax' | 'strict';
    encode?: (value: string) => string;
  }

  const yearInSeconds = 365 * 24 * 60 * 60;
  const cookieOptions: CookieSetOptions = {
    path: '/',
    maxAge: yearInSeconds,
    secure: true,
    httpOnly: false,
  };
  useEffect(() => {});

  useEffect(() => {
    return () => {
      removeCookie(authUsernameCookieName);
      removeCookie(authPasswordCookieName);
    };
  }, [removeCookie]);

  const login = async (
    values: AuthenticationRequest,
    onSuccess?: ApiSuccessCallback<AuthenticationResponse>,
    onError?: ApiErrorCallback
  ) => {
    const { data, error: ApiError } = await Api.post<
      AuthenticationResponse,
      AuthenticationRequest
    >('/auth/login/', values);
    setError(ApiError);
    setAuthenticationState(data);
    if (error && onError) {
      onError(error);
      console.log('error');
    }
    if (data) {
      // removeCookie(authUsernameCookieName);
      // removeCookie(authPasswordCookieName);
      if (!authCookieWasSet()) {
        console.log('cookies not set');
        Api.authenticate(data.token);
        setCookie(authUsernameCookieName, values.username, cookieOptions);
        setCookie(authPasswordCookieName, values.password, cookieOptions);
      } else console.log('cookies was set');
      if (onSuccess) onSuccess(data);
    }
  };

  const authCookieWasSet = () => {
    return (
      !!cookies[authUsernameCookieName] && !!cookies[authPasswordCookieName]
    );
  };

  const getUsernameAndPassword = () => {
    const values: AuthenticationRequest = {
      username: cookies[authUsernameCookieName],
      password: cookies[authPasswordCookieName],
    };
    return values;
  };

  const logout = () => {
    setAuthenticationState(undefined);
    setLoading(false);
    // storage.remove('authenticationState');
  };
  const loadFromStorage = () => {
    // setLoading(true);
    // storage.get('authenticationState', (_, data: AuthenticationResponse) => {
    //   setAuthenticationState(data);
    //   setLoading(false);
    // });
  };
  return {
    authCookieWasSet,
    getUsernameAndPassword,
    authenticationState,
    error,
    login,
    logout,
    loadFromStorage,
    loading,
  };
}

export const {
  Provider: AuthenticationProvider,
  useContainer: useAuthenticationService,
} = createContainer(useAuthentication);
