import { Stack, Box, Heading, SimpleGrid, HStack } from "@chakra-ui/react";
import { useState, useEffect, useCallback } from "react";
import { formatName } from "../../utils/employeeUtils";
import StatusIcon from "./StatusIcon";
import React from "react";
import { EmployeeDetailsPopUp } from "./EmployeeDetailsPopUp";
import EmployeeApi from "../../api/EmployeeApi";

const EmployeeStatus: React.FC = () => {
  // Employee APIs
  const employeesInfo = EmployeeApi.hooks.useEmployeeQuery();
  const employeeCurentDetails = EmployeeApi.hooks.useCurrentEmployeeStatus({
    refetchInterval: 30000,
  });

  const [startLongPress, setStartLongPress] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const close = () => setIsOpen(false);
  const [selectedEmp, setSelectedEmp] = useState();

  const onLongPress = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (startLongPress) {
      timerId = setTimeout(onLongPress, 1000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [onLongPress, startLongPress]);

  const start = useCallback((empDetail) => {
    setSelectedEmp(empDetail.employee);
    setStartLongPress(true);
  }, []);
  const stop = useCallback(() => {
    setStartLongPress(false);
  }, []);

  const userName = (info: {
    active?: boolean;
    is_in_pause?: boolean;
    employee?: number;
  }) => {
    const res = employeesInfo[0]?.data?.find(
      (i) => parseInt(i.id) === info.employee
    );
    if(res)
    return formatName(res);
  };
  return (
    <Stack padding={15}>
      <Stack>
        <Heading>Mitarbeiterstatus</Heading>
      </Stack>
      <Stack>
        <SimpleGrid columns={[2, null, 4]} spacing="40px">
          {employeeCurentDetails?.[0]?.data?.map(
            (
              empDetail: {
                active: boolean;
                is_in_pause: boolean;
                employee: number;
              },
              id: number
            ) => (
              <Box
                borderWidth="1px"
                borderRadius="3px"
                key={id}
                style={{ cursor: "pointer" }}
              >
                <HStack
                  onMouseDown={() => {
                    start(empDetail);
                  }}
                  onMouseUp={stop}
                  onMouseLeave={stop}
                  onTouchStart={() => {
                    start(empDetail);
                  }}
                  onTouchEnd={stop}
                >
                  <Box lineHeight="10px" padding="10px">
                    <StatusIcon
                      activeStatus={empDetail.active}
                      pauseStatus={empDetail.is_in_pause}
                    />
                  </Box>
                  <Box>{userName(empDetail)}</Box>
                </HStack>
                <EmployeeDetailsPopUp
                  emp_detail={empDetail}
                  isOpen={isOpen}
                  close={close}
                  selectedEmp={selectedEmp || 0}
                />
              </Box>
            )
          )}
        </SimpleGrid>
      </Stack>
    </Stack>
  );
};

export default EmployeeStatus;
