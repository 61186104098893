import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { AuthenticationProvider } from './services/AuthenticationService';
import { SettingsProvider } from './services/SettingsService';
import Routes from './Routes';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import theme from './theme';
import { HashRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
document.addEventListener('DOMContentLoaded', () =>
  render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
          <CSSReset />
          <AuthenticationProvider>
            <SettingsProvider>
              <HashRouter>
                <CookiesProvider>
                  <App>
                    <Routes />
                  </App>
                </CookiesProvider>
              </HashRouter>
            </SettingsProvider>
          </AuthenticationProvider>
        </ChakraProvider>
      </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById('root')
  )
);
