import { useEffect } from 'react';
import de from '../i18n/gen/de.properties.json';
import en from '../i18n/gen/en.properties.json';
import { useSettingsService } from '../services/SettingsService';
import { Language } from '../entities/Settings/SettingsTypes';
import _ from 'lodash';
const acceptedLanguages = {
  de,
  en,
};

type Parameters = string[];

function parameterize(params?: Parameters) {
  return (token: string) => {
    if (token.startsWith('#')) {
      const index = parseInt(token.charAt(1), 10);
      if (!params || params.length - 1 < index) {
        return token;
      }
      return params[index];
    }
    return token;
  };
}

function valid(text: any, language: Language) {
  return (
    !!text &&
    typeof text === 'string' &&
    _.get(acceptedLanguages, `${language}.${text}`)
  );
}

export function translate(
  text: string,
  language: Language,
  params?: Parameters
) {
  if (!language) return undefined;
  if (!valid(text, language)) {
    return text;
  }
  const translation: string = _.get(acceptedLanguages, `${language}.${text}`);

  return translation.split(' ').map(parameterize(params)).join(' ');
}

export function useGetLanguage() {
  const { language } = useSettingsService();
  return language || 'de';
}

function useTranslate() {
  const { language, loadSettingsFromStorage } = useSettingsService();
  useEffect(() => {
    if (!language) {
      loadSettingsFromStorage();
    }
  }, [language]);
  return (text: any, params?: Parameters) =>
    translate(text, language || 'de', params);
}

export default useTranslate;
