import { AxiosResponse } from 'axios';

export type Timestamp = number;
export type ID = number | string;
export interface IdProvider {
  id: ID;
}

export const unwrap = <T>(res: AxiosResponse<T>) => res.data;
export interface RequestOptions {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
}
