import React from "react";
import {
  ButtonProps as CButtonPros,
  Button as ChakraButton,
} from "@chakra-ui/react";
import useTranslate from "../../utils/translate";

type ButtonProps = CButtonPros & {
  params?: string[];
  ref?: React.MutableRefObject<any>;
};

export const Button: React.FC<ButtonProps> = ({
  params,
  children,
  ...props
}) => {
  const translate = useTranslate();
  return <ChakraButton {...props}>{translate(children, params)}</ChakraButton>;
};
