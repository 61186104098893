import axios from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Employee } from '../entities/Department/DepartmentTypes';
import { unwrap } from './apiCommons';

interface CurrentEmployeeStatus {
  employee: number;
  active: boolean;
  is_in_pause: boolean;
  current_job: number | null | undefined;
  current_job_type: 'RJ' | 'RR' | 'RS' | 'NJ';
}

export enum EmployeeKeys {
  EMPLOYEES = 'EMPLOYEES',
  EMPLOYEES_STATUS = 'EMPLOYEES_STATUS',
}

const EmployeeQueries = {
  getEmployees() {
    return axios.get<Employee[]>('/company/users/').then(unwrap);
  },
  getCurrentEmployeesStatus() {
    return axios.get<CurrentEmployeeStatus[]>('/employees/').then(unwrap);
  },
};

const EmployeeMutations = {};

const EmployeeHooks = {
  useEmployeeQuery(options?: UseQueryOptions<Employee[]>) {
    const queryClient = useQueryClient();
    const result = useQuery(
      EmployeeKeys.EMPLOYEES,
      EmployeeQueries.getEmployees,
      options
    );
    const QueryResultAndInvalidate: [typeof result, () => Promise<void>] = [
      result,
      () => queryClient.invalidateQueries(EmployeeKeys.EMPLOYEES),
    ];
    return QueryResultAndInvalidate;
  },
  useCurrentEmployeeStatus(options?: UseQueryOptions<CurrentEmployeeStatus[]>) {
    const queryClient = useQueryClient();
    const result = useQuery(
      EmployeeKeys.EMPLOYEES_STATUS,
      EmployeeQueries.getCurrentEmployeesStatus,
      options
    );
    const QueryResultAndInvalidate: [typeof result, () => Promise<void>] = [
      result,
      () => queryClient.invalidateQueries(EmployeeKeys.EMPLOYEES_STATUS),
    ];
    return QueryResultAndInvalidate;
  },
};

const EmployeeApi = {
  queries: EmployeeQueries,
  mutations: EmployeeMutations,
  hooks: EmployeeHooks,
};

export default EmployeeApi;
