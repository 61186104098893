import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import React from "react";

interface StatusIconProps {
  activeStatus: boolean
  pauseStatus: boolean
}
const StatusIcon: React.FC<StatusIconProps> = ({ activeStatus, pauseStatus }) => {
  return (
    <>
      {activeStatus === true && pauseStatus === false && (
        <CheckCircleIcon color="green" />
      )}
      {activeStatus === false && pauseStatus === false && (
        <WarningIcon color={"red"} />
      )}
      {activeStatus === true && pauseStatus === true && (
        <WarningIcon color={"yellow"} />
      )}
    </>
  );
};

export default StatusIcon;
