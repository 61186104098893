import { Box, Center, Flex, Stack, useToast } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React from 'react';
import { Button } from '../components/ui/Button';
import { Heading } from '../components/ui/Heading';
import { InputField } from '../components/ui/InputField';
import { Login } from '../components/icons/Login';
import { AuthenticationRequest } from '../entities/Authentication/AuthenticationRequest';
import { useAuthenticationService } from '../services/AuthenticationService';

const Authenticate: React.FC<{}> = () => {
  const { login } = useAuthenticationService();
  const toast = useToast();

  const errorCB = () => {
    toast({
      title: 'Loginfehler',
      description:
        'Diese Kombination aus Benutzername & Passwort ist uns nicht bekannt.',
      duration: 5000,
      isClosable: true,
      status: 'error',
    });
  };

  const onSubmit = (values: AuthenticationRequest) => {
    login(values, undefined, errorCB);
  };

  return (
    <Formik
      initialValues={{ username: '', password: '' } as AuthenticationRequest}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Center>
            <Stack w="70vw">
              <Heading m="auto" textAlign="center">
                authenticate.heading
              </Heading>
              <Flex mt={4} w="100%">
                <Login m="auto" boxSize="3em" />
              </Flex>
              <Box mt={4}>
                <InputField
                  name="username"
                  label="authenticate.username.label"
                  placeholder="authenticate.username.placeholder"
                  type="username"
                  required
                />
              </Box>
              <Box mt={4}>
                <InputField
                  name="password"
                  label="authenticate.password.label"
                  type="password"
                  placeholder="authenticate.password.placeholder"
                  required
                />
              </Box>
              <Flex mt={4}>
                <Button
                  type="submit"
                  colorScheme="green"
                  isLoading={isSubmitting}
                  mt={4}
                  w="100%"
                >
                  authenticate.submit.text
                </Button>
              </Flex>
            </Stack>
          </Center>
        </Form>
      )}
    </Formik>
  );
};

export default Authenticate;
