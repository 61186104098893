import { Image } from "@chakra-ui/react";
import { IconButton } from "@chakra-ui/button";
import { RepeatIcon } from "@chakra-ui/icons";
import { Center, Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/react";
import QRCode from "qrcode.react";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { io, Socket } from "socket.io-client";
import CompanyLogoApi from "../api/CompanyLogoApi";
import { useAuthenticationService } from "../services/AuthenticationService";

import { SocketEvents } from "../socketEvents";
import EmployeeStatus from "../components/ui/EmployeeStatus";

const ShowQRCode: React.FC = () => {
  const authenticationService = useAuthenticationService();
  const { authenticationState } = authenticationService;
  const token = useMemo(() => {
    return authenticationState?.token ?? "";
  }, [authenticationState]);
  const [qrToken, setQrToken] = useState<string | null>(null);
  const { data: logo } = CompanyLogoApi.hooks.useCompanyLogo(token);
  const [socket, setSocket] = useState<Socket>();
  const [qrSize] = useState(128);

  useEffect(() => {
    const url = process.env.REACT_APP_QR_SERVER_URL!;
    const socket = io(url, {
      auth: { token },
    });
    socket.on("connect_error", () => {
      socket.auth = { token };
      socket.connect();
    });

    socket.on(SocketEvents.NEW_QR_CODE, (data: string) => {
      console.log(data);
      setQrToken(`${data}`);
    });

    socket.on("connect", () => {
      socket.emit(SocketEvents.NEW_QR_CODE);
    });
    setSocket(socket);

    return () => {
      socket.disconnect();
    };
  }, [token]);

  const refreshToken = () => {
    socket?.emit(SocketEvents.NEW_QR_CODE);
  };

  return (
    <Fragment>
      <Stack>
        {logo?.picture && (
          <Image
            position="absolute"
            src={logo?.picture}
            top={5}
            left={5}
            maxH={150}
            maxW={300}
            zIndex={5}
          />
        )}
      </Stack>

      <Stack mt="10vh">
        <Center mt="17vh">
          <Stack
            justifyItems="center"
            alignItems="center"
            direction="row"
            spacing={10}
          >
            <QRCode value={qrToken || ""} size={qrSize} />
            <Text>{qrToken}</Text>
            <Stack>
              <Center>
                <Text fontSize="2xl">Bitte Scannen.</Text>
              </Center>
              <Center>
                <IconButton
                  aria-label="refresh"
                  icon={<RepeatIcon />}
                  onClick={refreshToken}
                  maxH={20}
                  maxW={40}
                />
              </Center>
            </Stack>
          </Stack>
        </Center>

        <EmployeeStatus />
      </Stack>
    </Fragment>
  );
};

export default ShowQRCode;
